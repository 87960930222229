<template>
  <div id="videoBuyResult">
    <!-- 头部 图片区域开始 -->
    <div data-v-7ba5bd90="" class="top-nav-bar has-shadow" style="">
      <div data-v-7ba5bd90="" class="van-nav-bar van-hairline--bottom">
        <div class="van-nav-bar__content">
          <div class="van-nav-bar__left"
            @click="$router.go(-2)"
          >
            <i
              data-v-7ba5bd90=""
              class="back-arrow van-icon van-icon-arrow-left"
              style="color: rgb(255, 255, 255)"
              ><!----></i
            >
          </div>
          <div class="van-nav-bar__title van-ellipsis">注文のお支払い</div>
        </div>
      </div>
    </div>
    <div class="header">
      <template v-if="type === 'success'">
        <van-image
          class="img"
          fit="cover"
          :src="require('@/assets/images/icon_success_01.png')"
        />
        <div>購入済み</div>
      </template>
      <template v-if="type === 'faild'">
        <van-image
          class="img"
          fit="cover"
          :src="require('@/assets/images/icon_faild_01.png')"
        />
        <div>支払いは失敗しました</div>
      </template>
    </div>
    <!-- 头部 图片区域结束 -->
    <!-- 底部开始 -->
    <div class="bottom">
      <template v-if="type === 'success'">
        <van-button type="default" :hairline="false" @click="showBindPay"
          >購入履歴を確認</van-button
        >
      </template>
      <template v-if="type === 'faild'">
        <van-button type="default" :hairline="false" @click="$router.go(-1)"
          >再度支払</van-button
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoBuyResult',
  data () {
    return {
      type: ''
    };
  },
  mounted () {
    const {
      params: { type }
    } = this.$route;
    console.log(type);
    this.type = type;
  },
  methods: {
    showBindPay () {
      let { $router } = this;
      $router.push({ name: 'MemberOrderList' });
    }
  }
};
</script>

<style scoped lang="less">
#videoBuyResult {
  position: relative;
  height: 100%;

  .header {
    padding-top: 100px;

    .img {
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }

    div {
      margin-top: 34px;
      font-size: 36px;
      line-height: 48px;
      text-align: center;
    }
  }

  .bottom {
    padding: 0 28px;
    margin-top: 138px;

    /deep/ button {
      width: 100%;
      height: 96px;
      font-family: Hiragino Sans;
      font-size: 28px;
      color: #4d2323;
      background: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
      border: none;
      border-radius: 16px;
      opacity: 1;
    }
  }
}
</style>